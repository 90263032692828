@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: "Manrope", sans-serif;
    font-weight: 500;
  }
}

@media print {
  .osano-cm-window,
  ._hj_feedback_container {
    display: none;
  }
}
*::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

*::-webkit-scrollbar-track {
  background: #fff; /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: #27ae60; /* color of the scroll thumb */
  border-radius: 20px; /* creates padding around scroll thumb */
}
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
::-webkit-scrollbar-button {
  width: 2px;
  height: 2px;
}
