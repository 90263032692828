li{
  margin: 6px 0;
}
.privacy-box {
  padding: 16px;
  margin-top: 90px;
}

.condition-box {
  padding: 16px;
}

.privacy-section {
  margin: 30px 0;
}

.privacy-section h3{
  font-weight: 700;
  margin-bottom: 10px;
}

.privacy-section small{
    text-align: center;
    display: block;
}

#privacy-title {
  text-align: center;
  margin: 20px 0;
}

.privacy-ul{
  padding: 0 40px;
}

.privacy-ul li {
  list-style: disc;
}

.numbered-li li{
  list-style: decimal;
}

.privacy-section p {
    margin: 20px 0;
}

.contact{
  display: flex;
  flex-direction: column;
}

/* .contact h3{
  margin-bottom: 20px;
} */
.contact p{
  margin: 6px 0;
}