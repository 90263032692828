.error-text{
  color: var(--danger-color);
  
}
#password-status{
  font-size: 0.8rem;
  color: var(--dark-color);
  position: relative;
  margin: 0.2rem 0 -0.5rem;
  padding: 0.4rem 0 0;
}
#password-status::after{
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  /* margin: 2px; */
  height: 5px;
  width: 100%;
  background-color: var(--light-color);
}
#password-status.password-invalid::after{
  background-color: unset;
  background: linear-gradient(to right, var(--danger-color) 33%, var(--light-color) 0)  !important;
}
#password-status.password-invalid--full::after{
  background-color: unset;
  background: linear-gradient(to right, var(--danger-color) 100%, var(--light-color) 0)  !important;
}
#password-status.password-medium::after{
  background-color: unset;
  background: linear-gradient(to right, var(--warning-color) 66%, var(--light-color) 0)  !important;
}
#password-status.password-valid::after{
  background-color: unset;
  background: linear-gradient(to right, var(--success-color) 100%, var(--light-color) 0)  !important;
}



.signup-form-group--button-inside{
  position: relative;
}
.signup-form-group--button-inside button{
  position: absolute;
  font-size: 1.2rem;
  height: 1rem;
  
  background-color: unset;
  border: none;
  top: 1.4rem;
  right: 1rem;
  outline: none;
  display: none;
}
.signup-form-group--button-inside button.display-password-button{
  cursor: pointer;
  display: block;
  color: var(--dark-color);
}

.error-message{
  font-size: 0.875rem;
  color: var(--danger-color);
}

.signup-form-group {
  /* margin: 20px 0; */
  padding: 0.6rem 0;
  max-width: 375px;
}

#signup-form-field-1,
#signup-form-field-2,
#signup-form-field-3,
#signup-form-field-4,
#signup-form-field-5,
#signup-form-field-6 {

  max-width: 375px;
}


#auth-form-title {
  font-weight: 600;
  color: var(--dark-color)
}


.signup-btn-div, .signin-btn-div {
  margin-top: 0.6rem;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.signup-btn-div p {
  max-width: 350px;
  text-align: center;
  margin-top: 12px;
}
#signup-btn{
  width: 100%;
  margin-right: 0;
  line-height: 3rem;
}

.form-link {
  color: var(--primary-color);
}

.flex-checkbox {
  display: flex;
  text-align: left;
}

.flex-checkbox input {
  margin-right: 5px;
  margin-top: 3px;
  /* height: 1.5rem;
  width: 1.5rem; */
}

#terms-text {
  text-align: center;
}


.flex-box {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}

.verify-account-container {
  min-height: 300px;
  margin: 140px 0 80px 0;
  text-align: center;
}

#verify-account-login-btn {
  margin-top: 40px;
}

.signup-container {
  width: 100%;
}

.auth-link {
  margin-top: 2rem;
  text-align: center;
}
.auth-link p{
  margin-top: 1rem;
}

/* MEDIA QUERIES MIN-WIDTH 320px */
@media screen and (min-width: 320px) {
  .flex-box {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .item-1 {
    order: 2;
    margin-top: 24px;
  }

  #auth-form-title {
    font-size: 30px;
    text-align: center;
  }

  .item-2 {
    order: 1;
  }
}

/* MEDIA QUERIES MIN-WIDTH 425px */
@media screen and (min-width: 425px) {
  #auth-form-title {
    font-size: 28px;
    text-align: center;
  }

}

/* MEDIA QUERIES MIN-WIDTH 768px */
@media screen and (min-width: 768px) {

  #auth-form-title {
    font-size: 36px;
  }

  .signup-btn-div p {
    margin-top: unset;
  }
 
  .signin-btn-div #signup-btn{
    width: unset;
  }
  .auth-link{
    margin-top: 0;
  }
  .auth-link p{
    margin-top: unset;
  }

  .signup-form-group--name {
    display: flex;
    flex-direction: row;
  }

  .signup-form-group--name .signup-form-group:first-of-type {
    margin-right: 1rem;
  }

  #signup-form-field-1,
  #signup-form-field-2,
  #signup-form-field-3,
  #signup-form-field-4,
  #signup-form-field-5,
  #signup-form-field-6 {
    max-width: unset;
  }
}

/* MEDIA QUERIES MIN-WIDTH 992px */
@media screen and (min-width: 992px) {
  .flex-box {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .item-1 {
    order: 1;
    width: 45%;
    margin-top: 0;
    max-width: 375px;
  }

  #auth-form-title {
    font-size: 30px;
    text-align: center;
  }

  .item-2 {
    order: 2;
    width: 45%;
  }
}