.modal {
	display: none;
	position: fixed;
	top: 20%;
	left: 0%;
	z-index: 2; /* Sit on top */
	width: 100%; /* Full width */
}

/* Modal Content */
.modal-content {
	background-color: #fefefe;
	margin: auto;
	padding: 20px;
	box-shadow: 2px;
	max-width: 600px;
	min-width: 400px;
	border-radius: 10px;
	height: max-content;
}

/* The Close Button */
.close {
	color: #aaaaaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
	margin-bottom: 1rem;
	border: none;
}

.close:hover,
.close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}

.creditCardForm {
	max-width: 600px;
	background-color: #fff;
	margin: auto;
	overflow: hidden;
	padding: 25px;
	color: #4c4e56;
}
.creditCardForm label {
	width: 100%;
	margin-bottom: 10px;
}
.creditCardForm .heading h1 {
	text-align: center;
	font-family: "Open Sans", sans-serif;
	color: #4c4e56;
}
.creditCardForm .payment {
	float: left;
	font-size: 18px;
	padding: 10px 25px;
	margin-top: 20px;
	position: relative;
}
.creditCardForm .payment .form-group {
	float: left;
	margin-bottom: 15px;
}
.creditCardForm .payment .form-control {
	line-height: 40px;
	height: auto;
	padding: 0 16px;
}
.creditCardForm .owner {
	width: 63%;
	margin-right: 10px;
}
.creditCardForm .CVV {
	width: 35%;
}
.creditCardForm #card-number-field {
	width: 100%;
}
.creditCardForm #expiration-date {
	width: 49%;
}
.creditCardForm #credit_cards {
	width: 50%;
	margin-top: 25px;
	text-align: right;
}
.creditCardForm #pay-now {
	width: 100%;
	margin-top: 25px;
}
.creditCardForm .payment .btn {
	width: 100%;
	margin-top: 3px;
	font-size: 24px;
	background-color: #2ec4a5;
	color: white;
}
.creditCardForm .payment select {
	padding: 10px;
	margin-right: 15px;
}
.transparent {
	opacity: 0.2;
}
@media (max-width: 650px) {
	.creditCardForm .owner,
	.creditCardForm .CVV,
	.creditCardForm #expiration-date,
	.creditCardForm #credit_cards {
		width: 100%;
	}
	.creditCardForm #credit_cards {
		text-align: left;
	}
	.modal-content {
		min-width: 360px;
		width: 80%;
	}

	.modal {
		top: 10%;
		width: 80%;
		margin-left: 10px;
	}
  .creditCardForm {
    padding: 5px;
    color: #4c4e56;
  }
}

.backdrop {
	position: fixed;
	backdrop-filter: blur(5px);
	display: none;
	width: 100%;
	height: 100%;
	z-index: 1;
	top: 0;
	left: 0;
	background-color: rgb(0 0 0 / 41%);
}
.modal-description {
	margin-top: 2rem;
	margin-bottom: 2rem;
}
.flex-group {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	max-height: max-content;
}
.full-width {
	max-width: 80%;
}
