.forgot-password-container{
    min-height: 300px;
    margin: 140px 0 80px 0;
    text-align: center;
    color: var(--dark-color);
}


.forgot-password-form-div{
    width: 500px;
    margin: 0 auto;
}

.redirect-link{
    color: var(--primary-color);
    opacity: 1;
}

.password-change-success-text{
    margin-top: 24px;
}

.password-change-redirect-text{
    margin-top: 20px;
}