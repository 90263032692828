.dashboard-container {
	min-height: 90vh;
	margin: 0 auto;
	/* width: 100vw; */
}

.dashboard-title-box {
	margin: 20px 0;
}

#dashboard-title {
	color: var(--dark-color);
	font-weight: 700;
	padding: 0 18px;
}

.dashboard-sections-box {
	/* width: 100%;
	min-height: 100vh; */
	display: flex;
	flex-direction: row;
}

/* .dashboard-nav-section {
	width: 250px;
	min-height: 100%;
	background-color: #bfe9d7;
	display: flex;
	flex-direction: column;
} */
.dashboard-header {
	padding: 18px;
	padding-bottom: 0;
	/* color: var(--primary-color); */
	color: #3c4a45;
	font-size: 24px;
	font-weight: 700;
	display: none;
}

.dashboard-nav-ul {
	padding: 18px;
	padding-top: 0;
}

.dashboard-nav-li {
	margin: 20px 0;
	position: relative;
}
.dashboard-nav-li a,
.dashboard-nav-li a:active,
.dashboard-nav-li a:hover {
	color: var(--dark-color);
}

.dashboard-nav-li span {
	margin-left: 20px;
}
.dashboard-nav-li svg {
	min-width: 16px;
	width: 16px;
	max-width: 16px;
}

/* .dashboard-content-section {
	min-height: 100%;
	width: calc(100% - 250px);
	padding: 18px 40px;
	background-color: #fafafa;
} */

#dashboard-content-title {
	color: var(--primary-color);
}
#dashboard-content-title span {
	font-weight: 600;
}

.dashboard-content-container {
	display: flex;
	flex-direction: column;
}

.dashboard-content-intro {
	margin: 10px auto;
	padding: 20px 18px;
	background-color: #fafafa;
	/* width: 100%; */
}

.dashboard-content-intro-headers {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.popupCloseBtn {
	cursor: pointer;
}

#dashboard-content-intro-title {
	color: var(--primary-color);
}

.dashboard-content-intro-title-svg {
	margin-right: 20px;
}

#dashboard-content-intro-title span {
	margin-right: 10px;
}

.dashboard-content-intro p {
	margin: 10px 0;
}

.dashboard-content-intro p {
	margin: 10px 0;
}

.dashboard-startpagina-api-container {
	min-height: 200px;
	display: flex;
	flex-direction: column;
	margin: 10px 0;
	padding: 16px 0;
}

.dashboard-startpagina-api-flexbox {
	display: flex;
	flex-direction: row;
	/* width: 100%; */
	min-height: 200px;
	justify-content: space-between;
	margin: 20px 0 0;
}

.dashboard-startpagina-api-div {
	width: 22%;
	height: 100%;
	text-align: center;
	position: relative;
}

.dashboard-startpagina-api-block {
	height: 300px;
	position: absolute;
	top: 0;
	left: 0;
	/* width: 100%; */
	background-repeat: no-repeat;
	background-size: contain;
}

.view-all-apis {
	color: var(--dark-color);
	display: flex;
	justify-content: flex-end;
}

.dashboard-startpagina-analytics-container {
	min-height: 200px;
	display: flex;
	flex-direction: row;
	margin: 10px 0;
	padding: 16px 0;
	justify-content: space-between;
}

.dashboard-startpagina-analytics-box {
	width: 50%;
	display: flex;
	flex-direction: column;
	padding: 16px;
	border-radius: 8px;
}

.dashboard-startpagina-analytics-graph {
	height: 200px;
	/* width: 100%; */
}

.dashboard-startpagina-account-box {
	/* width: 45%; */
	background-color: #fafafa;
	color: var(--dark-color);
	display: flex;
	flex-direction: column;
	padding: 16px;
	border-radius: 8px;
	box-shadow: 0 0 4px 0px rgba(128, 128, 128, 0.178);
}

.dashboard-startpagina-account-details {
	margin: 10px 0;
}

.dashboard-startpagina-account-details p {
	margin: 10px 0;
}

.dashboard-startpagina-faq-container {
	min-height: 200px;
	display: flex;
	flex-direction: column;
	padding: 16px 0;
	margin: 10px 0;
}

.dashboard-startpagina-faq-ul {
	margin: 20px 0;
}

.dashboard-startpagina-faq-ul li {
	margin-bottom: 16px;
}

.dashboard-startpagina-faq-title {
	font-weight: 600;
	color: var(--dark-color);
}

.dashboard-link-to-page {
	font-weight: 600;
	color: var(--dark-color);
}

.dashboard-link-to-page:first-child {
	margin-right: 1rem;
}

.dashboard-link-to-page:hover {
	color: var(--primary-color);
}

.dashboard-link-to-page:hover .goto-link-arrow {
	color: var(--primary-color);
}

.dashboard-content-account-container {
	display: flex;
	flex-direction: column;
	margin: 20px 0;
}

.dashboard-account-details {
	margin: 16px 0;
}

.dashboard-account-details p {
	margin: 14px 0;
}

.account-metered-billing-section {
	border-top: 1px solid #e8e6e6;
	border-bottom: 1px solid #e8e6e6;
	margin-bottom: 40px;
	padding: 40px 0;
}

.account-metered-billing-toggle {
	display: flex;
	flex-direction: row;
}

.account-metered-billing-toggle .metered-billing-title {
	margin-right: 20px;
}

.account-metered-billing-section > p {
	margin-top: 20px;
}

.btn-small {
	width: 250px;
	text-align: center;
	margin: 14px 0;
}

.dashboard-api-container {
	width: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	margin: 15px 0;
}

.dashboard-api-box {
	max-width: 300px;
	background-color: #fafafa;
	height: 350px;
	display: flex;
	flex-direction: column;
	border: 1px solid #f4f4f4;
	/* box-shadow: 0 0 8px 0px rgba(128, 128, 128, 0.178); */
	position: relative;
	transition: transform 0.3s ease-in;
}
.dashboard-api-box:hover {
	transform: scale(1.02);
}

.api-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 300px;
	background-repeat: no-repeat;
	background-size: contain;
}

.dashboard-api-link {
	color: #ffffff;
	width: 100%;
	height: fit-content;
	padding: 16px;
	text-align: center;
	background-color: var(--primary-color);
	position: absolute;
	left: 0;
	bottom: 0;
}

.api-box-hoverable {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
}

.api-box-hoverable img {
	align-self: center;
}

.api-text-hover {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	background-color: rgba(0, 0, 0, 0.7);
	color: #ffffff;
	display: none;
	align-items: center;
	text-align: center;
	padding: 0 20px;
}
.api-text-hover p {
	color: var(--light-color);
}

.currentPageBoldNavLink {
	font-weight: 600;
}

#apikeyHoverText {
	bottom: 20px;
}

#resterendeHoverText {
	bottom: 20px;
}

.goto-link-arrow {
	margin: 0 5px;

	color: var(--dark-color);
}

.goto-link:hover {
	color: var(--primary-color);
}
.dashboard-api-select {
	display: flex;
	flex-direction: row;
	align-items: center;
}
#api-select {
	width: 200px;
	margin: 0.5rem;
}

.berichten-indicator {
	position: absolute;
	right: 0;
	font-size: 12px;
	color: white;
	text-align: center;
	background: var(--dark-color);
	padding: 2px 6px;
	border-radius: 6px;
}

/* MEDIA QUERIES MIN-WIDTH 320px */
@media screen and (min-width: 280px) {
	#old-password-change-field,
	#new-password-change-field,
	#confirm-password-change-field {
		width: 100%;
	}
	.dashboard-nav-section {
		width: 80px;
	}
	.dashboard-nav-ul {
		text-align: center;
	}

	.dashboard-nav-span {
		display: none;
	}

	.dashboard-nav-li span {
		margin-left: 0;
	}

	.dashboard-content-section {
		width: calc(100% - 80px);
		padding: 18px 30px;
	}

	.dashboard-startpagina-api-flexbox {
		flex-direction: column;
	}
	.dashboard-api-box {
		/* width: 150px; */
		height: 290px;
		margin-bottom: 30px;
	}
	.api-image {
		height: calc(100% - 50px);
	}

	.dashboard-startpagina-analytics-container {
		flex-direction: column;
	}
	.dashboard-startpagina-analytics-box {
		width: 100%;
	}
	.dashboard-startpagina-account-box {
		width: 100%;
		overflow-wrap: anywhere;
	}

	.dashboard-account-details {
		overflow-wrap: anywhere;
	}
	.btn-small {
		width: 230px;
	}
	.dashboard-api-container {
		flex-direction: column;
	}
	.dashboard-startpagina-faq-ul li {
		margin-bottom: 20px;
	}
}

@media screen and (max-width: 425px) {
	.btn-account {
		width: 170px;
		line-height: unset;
		padding: 0.975rem;
		text-align: center;
		margin-bottom: 12px;
	}
	#apikeyHoverText {
		bottom: 60px;
		left: 0;
	}
	#resterendeHoverText {
		bottom: 30px;
		left: 0;
	}
}

/* MEDIA QUERIES MIN-WIDTH 425px */
@media screen and (min-width: 426px) {
	.dashboard-api-box {
		max-width: 220px;
		margin-right: 1rem;
	}
	.dashboard-api-box:last-of-type {
		margin-right: unset;
	}
	#old-password-change-field,
	#new-password-change-field,
	#confirm-password-change-field {
		width: 300px;
	}
}

/* MEDIA QUERIES MIN-WIDTH 625px */
@media screen and (min-width: 625px) {
	.dashboard-nav-section {
		width: 200px;
	}
	.dashboard-nav-icon {
		display: inline;
	}
	.dashboard-header {
		display: block;
	}
	.dashboard-nav-ul {
		text-align: left;
	}

	.dashboard-nav-span {
		display: inline;
	}
	.dashboard-nav-li span {
		margin-left: 20px;
	}
	.dashboard-content-section {
		width: calc(100% - 200px);
	}
	.dashboard-startpagina-api-flexbox {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: start;
	}
	.dashboard-startpagina-analytics-container {
		flex-direction: row;
	}
	.dashboard-startpagina-analytics-box {
		width: 50%;
	}
	.dashboard-startpagina-account-box {
		width: 50%;
	}
	.account__btn-group {
		display: flex;
	}
	.acoount__btn-group--support {
		margin-top: 2rem;
	}
	.acoount__btn-group--support div:first-of-type {
		margin-bottom: 0.5rem;
	}
}

/* MEDIA QUERIES MIN-WIDTH 768px */
@media screen and (min-width: 768px) {
	.dashboard-api-container {
		flex-direction: row;
	}
	.dashboard-api-box {
		max-width: 200px;
	}

	#old-password-change-field,
	#new-password-change-field,
	#confirm-password-change-field {
		width: 400px;
	}
}

/* MEDIA QUERIES MIN-WIDTH 992px */
@media screen and (min-width: 992px) {
	.dashboard-nav-section {
		width: 250px;
	}

	.dashboard-content-section {
		width: calc(100% - 250px);
	}

	.dashboard-api-box {
		width: unset;
		max-width: 220px;
		/* height: 430px; */
		margin-right: 1rem;
		margin-bottom: unset;
	}

	.dashboard-startpagina-faq-ul li {
		margin-bottom: 16px;
	}
	#old-password-change-field,
	#new-password-change-field,
	#confirm-password-change-field {
		width: 500px;
	}
}

@media screen and (min-width: 1920px) {
	.dashboard-container {
		max-width: 1920px;
	}
	/* .dashboard-nav-section{
    width: calc((100vw - 1400px)/2);;
  } */
	/* .dashboard-nav-section > * {
    display: inline-block;
    margin-left: auto;
    margin-right: 2rem;
  } */
	.dashboard-title-box {
		width: 1920px;
		margin: 20px auto;
		padding: 0;
	}
	h1#dashboard-title {
		padding: 0;
	}
}
