:root {
  --primary-color: #14a562;
  --secondary-color: #9a9a9a;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --warning-color: #ffc107;
  scroll-behavior: smooth;
}

* {
  font-family: "Manrope", sans-serif;
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
h4 {
  font-weight: bold;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.1875rem;
}
h4 {
  font-size: 1rem;
}
p {
  font-size: 1rem;
  line-height: 150%;
  color: "#707070";
}
a {
  font-size: 1rem;
}
label,
.secondary-text {
  font-size: 0.8125rem;
}
strong {
  font-weight: bold;
}
u {
  text-decoration: underline;
}
strong em {
  font-weight: bold;
  font-style: italic;
}
strong u {
  font-weight: bold;
  text-decoration: underline;
}

/*loading spinner*/
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--primary-color);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primary-color) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* tooltip element */
.tooltip-parent {
  position: relative;
}
svg.tooltip-parent {
  cursor: pointer;
  color: var(--dark-color);
}
.tooltip {
  position: absolute;
  display: none;
  background-color: var(--dark-color);
  color: var(--light-color);
  padding: 1rem;
  width: 250px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s ease-in;
  z-index: 100;
  height: fit-content;
}
.tooltip > * {
  color: var(--light-color);
}
.tooltip-parent:hover ~ .tooltip {
  opacity: 1;
  display: block;
}

.flex-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  max-width: 1400px;
  width: 80vw;
  margin: 0 auto;
  position: relative;
}

/* unified input styles for all forms */
.form .form-group {
  /* margin: 1.2rem 0; */
  padding: 0.6rem 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}
/* 
input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="number"],
input[type="file"],
select,
textarea {
  display: block;
  width: 100%;
  /* background-color: #fff; */

/* padding-left: 1.25rem; */

/* border: 1px solid var(--secondary-color);
	border-radius: 5px; */

input::placeholder {
  font-size: 14px;
  color: var(--secondary-color);
}

/* input validation */
.input--error ~ button.error-btn {
  cursor: pointer;
  color: var(--danger-color);
  display: block;
}
.input--success ~ button.success-btn {
  color: var(--success-color);
  top: 1.5rem;
  font-size: 1rem;
  display: block;
}

.input--success {
  box-shadow: 0px 0px 0px 1px var(--success-color);
  box-shadow: unset;
  position: relative;
}
.input--error,
.input--error:focus-within,
.input--error:focus,
.input--error:target {
  /* box-shadow:  0px 0px 0px 1px var(--danger-color)!important;   */
  outline: var(--danger-color) 1px auto;
}

/* buttons */
.btn {
  display: inline-block;
  background: var(--light-color);
  color: #333;
  line-height: 3rem;
  padding: 0 1.5625rem;
  font-size: 1rem;
  font-weight: 400;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: all 0.2s ease-in-out;
  outline: none;
  width: fit-content;
}
.btn.flow {
  width: 100%;
  margin-right: 0;
  padding: 0 3rem;
}
.text-primary {
  color: var(--primary-color);
}
.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}
.btn-outlined {
  background-color: var(--light-color);
  color: var(--primary-color);
}
.btn-danger-outlined {
  background-color: transparent;
  color: var(--danger-color);
  border: var(--danger-color) 1px solid;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color);
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color);
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff;
  color: #333;
}

.btn:hover {
  filter: brightness(1.16);
}
.btn:focus-within {
  filter: brightness(1.1);
}

.btn-disabled,
.btn-disabled:hover {
  cursor: default;
  opacity: 0.5;
  filter: unset;
}

.Toastify {
  position: absolute;
  top: 100px;
}

.form-slider {
  position: relative;
  display: flex !important;
  content: "";
  width: 35px !important;
  height: 32px !important;
  border-radius: 20% !important;
  box-shadow: 0 1px 1px rgb(0 0 0 / 50%);
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  justify-content: center;
  font-size: 10px;
  align-items: center;
  color: #fff;
  font-weight: 600;
}
/* api forms and result styles */
.api-container {
  background: #f5f9fc;
  background: #fbfbfa;
  min-height: 300px;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}
.api-container > button {
  margin-left: 5%;
  margin-top: 18px;
}
.api-header-flexbox {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: space-between;
  align-items: center;
}
.api-header-intro {
  display: flex;
  width: 60%;
  flex-direction: column;
  align-items: flex-start;
}

.api-header-doc {
  /* width: 20%; */
  text-align: end;
  align-self: center;
}

/* .api-form {
	width: 90%;
	margin: 8px auto;
	min-height: 344px;
	padding: 1rem 0;
}
.api-form .form {
	margin-top: 10px;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
} */
.api-form--search-form {
  width: 100%;
  min-height: unset;
  padding: 2rem;
}
.api-form .form input,
.api-form .form select {
  line-height: 2rem;
  margin-top: 0.5rem;
  background: #fbfbfa;
  border-color: var(--secondary-color);
  padding-left: 0.5rem;
  height: 4.5rem;
}
.search-group input {
  height: unset !important;
  margin-top: 0 !important;
}
.api-form select {
  font-size: 14px;
}

.api-form h1 {
  text-align: center;
  margin: 20px 0;
  color: var(--primary-color);
  font-weight: 600;
}
.optional-group-no-options {
  justify-content: flex-end !important;
}
.go-back-link {
  color: var(--dark-color);
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.go-back-link svg {
  margin-right: 0.725rem;
}
.go-back-link span {
  color: var(--dark-color);
  font-weight: 600;
}

.clients__header {
  color: #9a9a9a;
  text-transform: uppercase;
}

.clients__slider {
  width: 90%;
  height: 6rem;
  max-width: 1920px;
}

.clients__slide {
  padding: 0 0.5rem;
}

.clients__slide:focus {
  border: none;
  outline: none;
}

.clients__image {
  width: 100%;
  max-width: 80px;
}

@media screen and (max-width: 425px) {
  .container {
    width: 80vw;
  }

  .api-header-intro {
    display: flex;
    width: auto;
    align-items: center;
  }

  .plan-content-section {
    width: 100%;
    padding: 18px 40px;
    gap: 10px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  .plan-content-section button {
    width: 100%;
    gap: 10px;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  .toast {
    width: 90%;
    margin: auto;
    right: 0;
  }
}
@media screen and (max-width: 768px) {
  .api-header-intro {
    width: auto;
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  p,
  .btn {
    font-size: 1.125rem;
  }
  .secondary-text {
    font-size: 1rem;
  }
}
@media screen and (min-width: 1133px) {
  .api-header-flexbox {
    flex-direction: row;
  }
}

@media screen and (min-width: 1920px) {
  .container {
    width: 1920px;
  }
}

@media print {
  .container {
    width: 100%;
    margin-top: 16px;
  }
}
.full-height {
  height: 100%;
}

* {
  margin: 0px;
}
.load {
  width: 100%;
  background-color: none;
  /*     margin:0px; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.load div {
  width: 5px;
  height: 5px;
  background-color: var(--primary-color);
  margin-left: 5px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-top: calc(50px - 12.5px);
  animation-name: loading;
  animation-duration: 1.2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.load-two {
  animation-delay: 0.4s;
}
.load-three {
  animation-delay: 0.8s;
}
@keyframes loading {
  to {
    opacity: 0.3;
    transform: translateY(-25px);
    -webkit-transform: translateY(-25px);
    -moz-transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    -o-transform: translateY(-25px);
  }
}

.btn-change-password {
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 12px 0;
  border-radius: 20px;
  height: 40px;
  text-align: center;
}

#form-question {
  font-weight: 700;
  text-align: center;
  margin: 8px 0;
}

.form-required-fields,
.form-optional-fields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem 1.5rem;
  align-items: center;
}
.form-optional-fields {
  margin: 1rem 0;
  border: 1px solid var(--light-color);
  border-radius: 10px;
}

.api-form.btn-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 1.5rem 1rem;
}
.optional-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 1.5rem 1rem;
}
.optional-group button:last-of-type {
  margin-right: 0;
}
.optional-group-opened {
  flex-direction: column;
}
.optional-group-opened button:first-of-type {
  align-self: flex-start;
}
.optional-group-opened button:last-of-type {
  align-self: flex-end;
}
.show-options-btn {
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  cursor: pointer;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#checkboxes {
  display: none;
  border: 1px #dadada solid;
}

#checkboxes label {
  display: flex;
  align-items: center;
  padding: 0 8px;
}

#checkboxes label span {
  margin-top: 6px;
  margin-left: 10px;
}

#checkboxes label:hover {
  background-color: var(--primary-color);
}

.selectMultiple {
  background: #333;
  color: #ffffff;
}

#target-label-slider {
  width: 100%;
  height: 14px;
  touch-action: none;
  display: inline-table;
}

hr {
  border-bottom: none;
  /* margin: 10px 0; */
  opacity: 0.5;
}

/* 
 
API RESULT
Address Details Container 

*/

.address-details-container {
  margin-top: 20px;
  /* background: #f5f9fc; */
  min-height: 300px;
  /* margin-bottom: 100px; */
}

.details-box {
  padding: 0 12px;
}

.form-reset-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.map-box {
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
}

.map {
  height: 200px;
  position: relative;
}

#map {
  height: 200px;
  width: 100%;
}

.overview-title {
  color: #000000;
  opacity: 0.5;
  font-size: 13px;
  font-weight: 600;
}

.overview-info {
  color: #000000;
  opacity: 1;
  font-weight: 600;
}

.overview-info svg {
  vertical-align: text-top;
  font-size: 18px;
}

#label-current {
  background: #facf5a;
  margin-right: 5px;
}

#label-potential {
  background: var(--primary-color);
  margin-left: 5px;
}

.download-overview {
  position: absolute;
  bottom: 0;

  border-radius: 3rem;

  margin-bottom: -20px;
}

.download-overview svg {
  vertical-align: middle;
}

.property-details {
  width: 100%;
}

.property-details > h2 {
  margin-bottom: 20px;
  text-align: center;
  color: var(--dark-color);
}

.result-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  margin: auto;
}

.house-details,
.measures-details {
  height: fit-content;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
}

.house-details :nth-last-child(1),
.measures-details :nth-last-child(1) {
  border-bottom: none;
}

.result-item {
  width: 80%;
  font-size: 13px;
  padding: 16px;
  margin-bottom: 10px;
  margin: auto;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: column;
}

.result-title {
  font-weight: 600;
  color: var(--primary-color);
}

.result-item p {
  margin: 10px 0;
}

.item-box {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.item #key {
  color: var(--secondary-color);
  width: 50%;
  overflow-wrap: break-word;
}

.item #value {
  font-weight: 500;
  width: 30%;
  overflow-wrap: break-word;
}

.standardLabel {
  position: relative;
}

.standardLabel__letter {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(25px, -15px);
  font-weight: 600;
  color: #ffffff;
}
.form-reset-box button {
  width: auto;
}

.labelAA {
  transform: translate(18px, -15px);
}
.labelAAA {
  transform: translate(12px, -15px);
}
.labelAAAA {
  transform: translate(5px, -15px);
}

/* ================================================================================================== */
/* MEDIA QUERIES MIN-WIDTH 320px */
@media screen and (min-width: 320px) {
  .eco-header-flexbox {
    flex-direction: column;
  }
  .eco-header-intro {
    width: 100%;
    align-items: center;
  }

  .eco-header-doc {
    width: 100%;
    text-align: center;
    margin: 20px 0;
  }

  .btn-change-password {
    font-size: 14px;
    width: 160px;
  }

  #form-question {
    font-size: 17px;
  }

  .form-required-fields .form-group,
  .form-optional-fields .form-group {
    width: 100%;
  }
  .form-group label {
    font-size: 12px;
  }

  /* ADDRESS DETAILS CONTAINER */
  .map-box h2 {
    margin: 4px 0;
    color: var(--dark-color);
  }
  .map {
    margin: 24px 0;
    width: 100%;
  }
  .property-overview {
    background: #ffffff;
    position: absolute;
    width: 100%;
    left: 50%;
    margin-left: -50%;
    height: 260px;
    bottom: 0;
    margin-bottom: -270px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
    border-radius: 10px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
  .initial-overview {
    width: 40%;
    height: 110px;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-evenly;
  }
  .overview-info {
    font-size: 18px;
  }
  #label-current,
  #label-potential {
    display: inline-block;
    width: 25px;
    font-size: 13px;
    padding: 5px;
    border-radius: 16px;
    color: #fff;
  }

  .download-overview span {
    margin-right: 6px;
  }
  .property-details {
    margin-top: 285px;
    padding: 0;
  }
  .property-details > p {
    font-size: 20px;
  }
  .result-box {
    flex-direction: column;
  }
  .house-details,
  .measures-details {
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
    padding: 1rem 0;
  }
  .result-item {
    padding: 8px;
    font-size: 12px;
  }
  .form-reset-box {
    margin: 20px 0;
    flex-direction: column;
    justify-content: center;
  }
  .form-reset-box button {
    margin-bottom: 10px;
  }
  .eco-header-flexbox {
    flex-direction: column;
  }
  .eco-header-intro {
    width: 100%;
    align-items: center;
  }
}

/* MEDIA QUERIES MIN-WIDTH 425px */
@media screen and (min-width: 425px) {
}

/* MEDIA QUERIES MIN-WIDTH 625px */
@media screen and (min-width: 625px) {
  #form-question {
    font-size: 18px;
  }

  .form-group label {
    font-size: 13px;
  }

  .property-overview {
    height: 200px;
    margin-bottom: -220px;
  }
  .initial-overview {
    width: 25%;
  }
  .property-details {
    margin-top: 235px;
  }

  .result-item {
    font-size: 13px;
  }

  .form-reset-box {
    flex-direction: row;
  }
}

/* MEDIA QUERIES MIN-WIDTH 768px */
@media screen and (min-width: 768px) {
  .form-required-fields .form-group,
  .form-optional-fields .form-group {
    width: 45%;
  }
  /* ADDRESS CONTAINER */
  .map-box h2 {
    margin: 6px 0;
  }
  .map {
    width: 70%;
    height: 200px;
  }
  .property-overview {
    width: 120%;
    margin-left: -60%;
    margin-bottom: -160px;
  }
  .overview-title {
    font-size: 14px;
  }
  .overview-info {
    font-size: 20px;
  }

  .download-overview span {
    margin-right: 10px;
  }
  .property-details {
    margin-top: 190px;
  }
  .house-details,
  .measures-details {
    width: 100%;
    max-width: 600px;
  }
  .result-box {
    justify-content: space-around;
    flex-direction: row;
    gap: 1rem;
    /* width: 90%; */
  }
}

/* MEDIA QUERIES MIN-WIDTH 992px */
@media screen and (min-width: 992px) {
  .form-required-fields .form-group,
  .form-optional-fields .form-group {
    width: 30%;
  }
  .eco-header-flexbox {
    flex-direction: row;
  }

  .eco-header-intro {
    width: 100%;
    align-items: flex-start;
  }

  .eco-header-flexbox {
    flex-direction: row;
  }

  .eco-header-intro {
    width: 80%;
    align-items: flex-start;
  }

  .eco-header-doc {
    width: 20%;
    text-align: end;
    align-self: center;
    margin: 0;
  }

  .house-details,
  .measures-details {
    width: 100%;
    max-width: 600px;
  }
  .form-reset-box {
    justify-content: flex-end;
  }
}

/* MEDIA QUERIES MIN-WIDTH 1024px */
@media screen and (min-width: 1024px) {
  /* .form-optional-fields .form-group {
    width: 18%;
  } */
}

@media screen and (min-width: 1920px) {
  .eco-value-container {
    width: 1920px;
  }
}

@media print {
  .address-details-container {
    background-color: unset;
  }
  .eco-header-intro {
    margin-top: 1rem;
    margin-left: 1rem;
  }
  .form-reset-box {
    display: none;
  }
  .map-box {
    margin-bottom: 20px;
    break-after: always;
    height: unset;
  }
  #map {
    height: 270px;
  }
  .map-box > * {
    width: 100%;
    margin-left: 1rem;
  }
  .map-box h2 {
    text-align: left;
    /* font-weight: 600; */
    /* font-size: 24px; */
    line-height: 150%;
  }
  .map {
    margin-top: 2rem;
    /* margin: 1rem; */
    position: relative;
    break-after: always;
    height: unset;
  }
  .property-overview {
    position: relative;
    text-align: left;
    justify-content: space-evenly;
    padding: unset;
    margin-top: 1rem;
    width: unset;
    height: unset;
    margin-bottom: 0;
    break-after: always;
  }
  .initial-overview:first-of-type {
    margin-top: 2rem;
  }
  .initial-overview {
    align-items: flex-start;
    margin: 1rem 0;
    display: flex;
    width: 70%;
  }
  .initial-overview .overview-title {
    width: 50%;
    opacity: 1;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .btn-download-report {
    display: none;
  }
  .property-details {
    width: unset;
    margin-top: 0px;
  }
  .property-details > h2 {
    text-align: left;
    margin-left: 1rem;
  }

  .result-box {
    display: block;
    width: 100%;
    justify-content: flex-start;
  }
  .result-title {
    color: black;
  }
  .house-details,
  .measures-details {
    width: 70%;
  }
  .house-details {
    break-after: always;
  }
  .house-details::before {
    content: "Woning kenmerken";
  }
  .measures-details::before {
    content: "Verduurzamingsadvies";
  }
  .house-details::before,
  .measures-details::before {
    margin-left: 1rem;
    font-size: 1.1875rem;
    font-weight: 600;
  }

  .result-item {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
  }
  .result-item p {
    margin: 2px 0;
  }
  .item > #key {
    opacity: 1;
    margin-left: 1rem;
  }
  .item > p#value {
    width: 50%;
  }
}
/* 
   AVM RESULT PAGE AND OTHER API PAGES
*/

.avm-mapbox {
  width: 100%;
  max-width: 600px;
}
.avm-result-box {
  display: flex;
  justify-content: space-evenly;
  gap: 3rem;
}

.avm-result-box .house-details {
  width: 45%;
  margin-bottom: 20px;
  background: #ffffff;
  border-radius: 15px;
}

.avm-mapbox .house-details {
  width: 100%;
  max-width: 600px;
  min-height: 316px;
}

.avm-map {
  height: 200px;
  width: 100%;
  margin-bottom: 20px;
}

#map {
  width: 100%;
  height: 200px;
}
.property-details-box h2 {
  text-align: center;
  color: var(--dark-color);
  margin: 1rem;
}
.image-api-request-form {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  max-width: 600px;
}
.image-api-request-form label {
  min-width: fit-content;
  align-self: left;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}
.image-api-request-form input {
  margin-top: 0 !important;
}
.image-api-request-form span {
  align-self: center;
  margin: 0.5rem 0;
}
.image-api-request-form span::before,
.image-api-request-form span::after {
  content: "-";
  margin: 0.5rem;
}
.image-api-request-form-group {
  min-width: fit-content;
  display: flex;
  flex-direction: column;
}
.image-api-preview {
  width: 100%;
  min-width: 300px;
  max-width: 600px;
  height: 316px;
  background: var(--light-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  color: var(--dark-color);
  border: 1px solid #1e9d66;
  border-radius: 5px;
  margin-bottom: 1rem;
  object-fit: cover;
}
.form-required-fields-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
}
.form-required-fields-image img {
  height: 100%;
  max-width: 100%;
}
#form-title {
  display: flex;
  flex-direction: row;
}

/* MEDIA QUERIES MIN-WIDTH 768px */
@media screen and (min-width: 768px) {
  .avm-mapbox {
    width: 100%;
    max-width: 600px;
  }

  .avm-result-box .house-details {
    width: 100%;
    max-width: 600px;
  }

  .form-required-fields-image {
    flex-direction: row;
  }
}
@media screen and (max-width: 768px) {
  .image-api-preview {
    margin: 1rem 0;
  }
}
@media screen and (max-width: 1200px) {
}

@media screen and (min-width: 1920px) {
  .avm-container {
    width: 1920px;
  }
}

@media print {
  #form-title {
    color: black;
    margin-left: 1rem;
  }

  .property-details-box h2 {
    text-align: left;
  }

  .avm-container {
    min-height: unset;
  }

  .avm-result-box .house-details {
    width: 100%;
    max-width: 600px;
    margin-bottom: 0;
    /* break-after: unset; */
  }

  .avm-mapbox .house-details {
    /* margin-top: 20px; */
    margin-bottom: 20px;
  }

  .avm-map {
    margin-bottom: 20px;
    height: 270px;
  }
  .result-item {
    padding: 0;
  }
  .result-box {
    justify-content: flex-start;
  }
  .result-box::after {
    content: "Copyright © Altum AI B.V." url("./imgs/altum.png");
    position: absolute;
    bottom: 0;

    transform: scale(0.6) translateX(1.5rem);
    transform-origin: left bottom;
  }
  .result-title {
    margin-top: 0;
    padding-top: 0;
    margin-left: 1rem;
  }

  .avm-result-box .house-details::before {
    content: "";
  }

  .avm-mapbox {
    width: 100%;
    max-width: 600px;
  }
  .avm-map {
    width: calc(100% - 2rem);
    margin: 0 auto;
  }
  .image-api-preview {
    margin: 1rem 0;
  }
}

/* 
PLAN PICKER
*/

.plan-picker-container {
  display: flex;
  flex-direction: column;
  background-color: var(--light-color);
  width: 100%;
  padding-bottom: 3rem;
  color: var(--dark-color);
}
.plan-picker {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
  width: 100%;
}
.plan-content-section {
  width: 100%;
  padding: 18px 40px;
  /* margin: 0 auto; */
}

.plan-flex {
  margin: 0 auto;
}

.tax-message {
  margin: 6px 0;
  font-size: 13px;
  font-style: italic;
}

.tax-message-title {
  font-size: 13px;
  font-style: italic;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-width: 250px;
  width: 100%;
  max-width: 400px;
  border: 1px solid var(--light-color);
  box-shadow: 0 0 11px -10px var(--primary-color);
  border-radius: 8px;
  padding: 1.5rem;
  background-color: white;
}
.plan-icon {
  margin: 1rem 0 0;
}
.plan-name {
  font-size: 20px;
  margin: 1rem 0 1rem 0;
}
.plan-fee span:first-of-type {
  font-size: 32px;
  letter-spacing: -1px;
  margin: 5px 0 5px 0;
  font-weight: 500;
}
.plan-features {
  margin: 1rem 0;
  text-align: left;
}
.plan-feature-items {
  display: flex;
  font-size: 13px;
  margin: 0.5rem 0 0;
  padding: 1em 0;
  font-size: 13px;
  display: flex;
}

.plan-feature-items i {
  font-size: 15px;
  margin-right: 1em;
  padding: 2px 3px;
  height: 20px;
  width: 20px;
}
@media screen and (max-width: 525px) {
  .plan-picker {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width: 526px) and (max-width: 940px) {
  .plan-picker {
    display: grid;
    grid-template-areas: "a b";
  }
}
/* @media screen and (min-width: 941px) and (max-width: 1024px) {
	.plan-picker {
		display: grid;
		grid-template-areas: "a b c" "d d d";
		grid-template-columns: repeat(3, 1fr);
		width: 100%;
	}
	.plan-picker:first-child{
		grid-area: a;
	}
	.plan-picker .card:nth-child(1){
		grid-area: b;
	}
	.plan-picker .card:nth-child(2){
		grid-area: c;
	}
	.plan-picker .card:nth-child(3){
		grid-area: d;
	}
} */

/* 
  API CARD
*/

.api-list {
  margin: 1rem 0 0;
  width: fit-content;
}
.api-list h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}
.api-list h3 svg {
  margin-right: 1rem;
}
.api-list__container {
  margin: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.api-list__container--landing {
  margin: 2.5rem 0 0;
  justify-content: center;
}
.api-card {
  width: 100%;
  max-width: 275px;
  /* height: 400px; */
  border: 1px solid var(--light-color);
  box-shadow: 0 0 11px -10px var(--primary-color);
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  text-align: initial;
}

a.api-card__image {
  width: 100%;
  /* height: auto; */
  flex: 0.35;
  border-bottom: 1px solid var(--light-color);
}
img.api-card__image {
  width: 100%;
}
.api-card__content {
  flex: 0.65;
  width: 100%;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
h4.api-card__name {
  color: var(--dark-color);
}
a.api-card__name {
  color: var(--primary-color);
  font-weight: 600;
  cursor: pointer;
}
.api-card__name--disabled {
  color: var(--dark-color) !important;
}

.api-card__content {
  font-weight: 600;
}
.api-card__description {
  margin: 1rem 0 0;
  line-height: 150%;
}
.api-card__btn-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.api-card__demo {
  color: var(--primary-color);
}
.api-card__demo--disabled {
  color: var(--dark-color);
}
.api-card__docs {
  font-size: 1.5rem;
}
.api-card__docs svg {
  color: var(--dark-color);
}
.api-list__link {
  color: var(--dark-color);
  font-weight: 600;
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 1rem;
  margin-left: auto;
}
.api-list__link svg {
  margin-left: 0.3rem;
}
.api-demo-label {
  background-color: var(--primary-color);
  color: white;
  font-weight: 600;
  font-size: 0.8rem;
  margin-left: 5px;
  border-radius: 50px;
  padding: 5px;
}
.api-beta-label {
  background-color: var(--warning-color);
  height: fit-content;
  align-self: center;
}
.font-small {
  font-size: 15px;
}
.arrow {
  background-color: var(--dark-color);
  color: var(--light-color);
  width: 2rem;
  height: auto;
  border: none;
  padding: 7px 0 5px 8px;
  border-radius: 50%;
  float: right;
}

/* 
API DEMO 
*/

.api-demo-container {
  margin-top: 2rem auto;
  min-height: 300px;
}

.api-demo-container p {
  margin-top: 100px;
  text-align: center;
}
.api-demo-container p a {
  color: var(--primary-color);
  font-size: unset;
}

.api-demo-container .goto-api-link {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

/* 
Reference API
*/

.reference-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
}
.reference-result-container:hover {
  cursor: pointer;
}

.absolute {
  position: absolute;
}
.result-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  grid-gap: 1rem;
}
@media screen and (max-width: 700px) {
  .result-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1rem;
  }
}
/* DASHBOARD */

.dashboard-container {
  min-height: 90vh;
  margin: 0 auto;
}

.dashboard-title-box {
  margin: 20px 0;
}

#dashboard-title {
  color: var(--dark-color);
  font-weight: 700;
  padding: 0 18px;
}

.dashboard-sections-box {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
}

.dashboard-nav-section {
  width: 250px;
  min-height: 100%;
  background-color: #bfe9d7;
  display: flex;
  flex-direction: column;
}
.dashboard-header {
  padding: 18px;
  padding-bottom: 0;
  /* color: var(--primary-color); */
  color: #3c4a45;
  font-size: 24px;
  font-weight: 700;
  display: none;
}

.dashboard-nav-ul {
  padding: 18px;
  padding-top: 0;
}

.dashboard-nav-li {
  margin: 20px 0;
  position: relative;
}
.dashboard-nav-li a,
.dashboard-nav-li a:active,
.dashboard-nav-li a:hover {
  color: var(--dark-color);
}

.dashboard-nav-li span {
  margin-left: 20px;
}
.dashboard-nav-li svg {
  min-width: 16px;
  width: 16px;
  max-width: 16px;
}

.dashboard-content-section {
  min-height: 100%;
  width: calc(100% - 250px);
  padding: 18px 40px;
  background-color: #fafafa;
}

#dashboard-content-title {
  color: var(--primary-color);
}
#dashboard-content-title span {
  font-weight: 600;
}

.dashboard-content-container {
  display: flex;
  flex-direction: column;
}

.dashboard-content-intro {
  margin: 10px auto;
  padding: 20px 18px;
  background-color: #ffffff;
  width: 100%;
}

.dashboard-content-intro-headers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.popupCloseBtn {
  cursor: pointer;
}

#dashboard-content-intro-title {
  color: var(--primary-color);
}

.dashboard-content-intro-title-svg {
  margin-right: 20px;
}

#dashboard-content-intro-title span {
  margin-right: 10px;
}

.dashboard-content-intro p {
  margin: 10px 0;
}

.dashboard-content-intro p {
  margin: 10px 0;
}

.dashboard-startpagina-api-container {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  padding: 16px 0;
}

.dashboard-startpagina-api-flexbox {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 200px;
  justify-content: space-between;
  margin: 20px 0 0;
}

.dashboard-startpagina-api-div {
  width: 22%;
  height: 100%;
  text-align: center;
  position: relative;
}

.dashboard-startpagina-api-block {
  height: 300px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

.view-all-apis {
  color: var(--dark-color);
  display: flex;
  justify-content: flex-end;
}

.dashboard-startpagina-analytics-container {
  min-height: 200px;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  padding: 16px 0;
  justify-content: space-between;
}

.dashboard-startpagina-analytics-box {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
}

.dashboard-startpagina-analytics-graph {
  height: 200px;
  width: 100%;
}

.dashboard-startpagina-account-box {
  width: 45%;
  background-color: #ffffff;
  color: var(--dark-color);
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 4px 0px rgba(128, 128, 128, 0.178);
}

.dashboard-startpagina-account-details {
  margin: 10px 0;
}

.dashboard-startpagina-account-details p {
  margin: 10px 0;
}

.dashboard-startpagina-faq-container {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  margin: 10px 0;
}

.dashboard-startpagina-faq-ul {
  margin: 20px 0;
}

.dashboard-startpagina-faq-ul li {
  margin-bottom: 16px;
}

.dashboard-startpagina-faq-title {
  font-weight: 600;
  color: var(--dark-color);
}

.dashboard-link-to-page {
  font-weight: 600;
  color: var(--dark-color);
}

.dashboard-link-to-page:first-child {
  margin-right: 1rem;
}

.dashboard-link-to-page:hover {
  color: var(--primary-color);
}

.dashboard-link-to-page:hover .goto-link-arrow {
  color: var(--primary-color);
}

.dashboard-content-account-container {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.dashboard-account-details {
  margin: 16px 0;
}

.dashboard-account-details p {
  margin: 14px 0;
}

.account-metered-billing-section {
  border-top: 1px solid #e8e6e6;
  border-bottom: 1px solid #e8e6e6;
  margin-bottom: 40px;
  padding: 40px 0;
}

.account-metered-billing-toggle {
  display: flex;
  flex-direction: row;
}

.account-metered-billing-toggle .metered-billing-title {
  margin-right: 20px;
}

.account-metered-billing-section > p {
  margin-top: 20px;
}

.btn-small {
  width: 250px;
  text-align: center;
  margin: 14px 0;
}

.dashboard-api-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 15px 0;
}

.dashboard-api-box {
  max-width: 300px;
  background-color: white;
  height: 350px;
  display: flex;
  flex-direction: column;
  border: 1px solid #f4f4f4;
  /* box-shadow: 0 0 8px 0px rgba(128, 128, 128, 0.178); */
  position: relative;
  transition: transform 0.3s ease-in;
}
.dashboard-api-box:hover {
  transform: scale(1.02);
}

.api-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-size: contain;
}

.dashboard-api-link {
  color: #ffffff;
  width: 100%;
  height: fit-content;
  padding: 16px;
  text-align: center;
  background-color: var(--primary-color);
  position: absolute;
  left: 0;
  bottom: 0;
}

.api-box-hoverable {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
}

.api-box-hoverable img {
  align-self: center;
}

.api-text-hover {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  display: none;
  align-items: center;
  text-align: center;
  padding: 0 20px;
}
.api-text-hover p {
  color: var(--light-color);
}

.currentPageBoldNavLink {
  font-weight: 600;
}

#apikeyHoverText {
  bottom: 20px;
}

#resterendeHoverText {
  bottom: 20px;
}

.goto-link-arrow {
  margin: 0 5px;

  color: var(--dark-color);
}

.goto-link:hover {
  color: var(--primary-color);
}
.dashboard-api-select {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
#api-select {
  width: 200px;
  margin: 0.5rem;
}

.berichten-indicator {
  position: absolute;
  right: 0;
  font-size: 12px;
  color: white;
  text-align: center;
  background: var(--dark-color);
  padding: 2px 6px;
  border-radius: 6px;
}

/* MEDIA QUERIES MIN-WIDTH 320px */
@media screen and (min-width: 320px) {
  #old-password-change-field,
  #new-password-change-field,
  #confirm-password-change-field {
    width: 100%;
  }
  .dashboard-nav-section {
    width: 80px;
  }
  .dashboard-nav-ul {
    text-align: center;
  }

  .dashboard-nav-span {
    display: none;
  }

  .dashboard-nav-li span {
    margin-left: 0;
  }

  .dashboard-content-section {
    width: calc(100% - 80px);
    padding: 18px 30px;
  }

  .dashboard-startpagina-api-flexbox {
    flex-direction: column;
  }
  .dashboard-api-box {
    /* width: 150px; */
    height: 290px;
    margin-bottom: 30px;
  }
  .api-image {
    height: calc(100% - 50px);
  }

  .dashboard-startpagina-analytics-container {
    flex-direction: column;
  }
  .dashboard-startpagina-analytics-box {
    width: 100%;
  }
  .dashboard-startpagina-account-box {
    width: 100%;
    overflow-wrap: anywhere;
  }

  .dashboard-account-details {
    overflow-wrap: anywhere;
  }
  .btn-small {
    width: 230px;
  }
  .dashboard-api-container {
    flex-direction: column;
  }
  .dashboard-startpagina-faq-ul li {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 425px) {
  .btn-account {
    width: 170px;
    line-height: unset;
    padding: 0.975rem;
    text-align: center;
    margin-bottom: 12px;
  }
  #apikeyHoverText {
    bottom: 60px;
    left: 0;
  }
  #resterendeHoverText {
    bottom: 30px;
    left: 0;
  }
}

/* MEDIA QUERIES MIN-WIDTH 425px */
@media screen and (min-width: 426px) {
  .dashboard-api-box {
    max-width: 220px;
    margin-right: 1rem;
  }
  .dashboard-api-box:last-of-type {
    margin-right: unset;
  }
  #old-password-change-field,
  #new-password-change-field,
  #confirm-password-change-field {
    width: 300px;
  }
}

/* MEDIA QUERIES MIN-WIDTH 625px */
@media screen and (min-width: 625px) {
  .dashboard-nav-section {
    width: 200px;
  }
  .dashboard-nav-icon {
    display: inline;
  }
  .dashboard-header {
    display: block;
  }
  .dashboard-nav-ul {
    text-align: left;
  }

  .dashboard-nav-span {
    display: inline;
  }
  .dashboard-nav-li span {
    margin-left: 20px;
  }
  .dashboard-content-section {
    width: calc(100% - 200px);
  }
  .dashboard-startpagina-api-flexbox {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
  }
  .dashboard-startpagina-analytics-container {
    flex-direction: row;
  }
  .dashboard-startpagina-analytics-box {
    width: 50%;
  }
  .dashboard-startpagina-account-box {
    width: 50%;
  }
  .account__btn-group {
    display: flex;
  }
  .acoount__btn-group--support {
    margin-top: 2rem;
  }
  .acoount__btn-group--support div:first-of-type {
    margin-bottom: 0.5rem;
  }
}

/* MEDIA QUERIES MIN-WIDTH 768px */
@media screen and (min-width: 768px) {
  .dashboard-api-container {
    flex-direction: row;
  }
  .dashboard-api-box {
    max-width: 200px;
  }

  #old-password-change-field,
  #new-password-change-field,
  #confirm-password-change-field {
    width: 400px;
  }
}

/* MEDIA QUERIES MIN-WIDTH 992px */
@media screen and (min-width: 992px) {
  .dashboard-nav-section {
    width: 250px;
  }

  .dashboard-content-section {
    width: calc(100% - 250px);
  }

  .dashboard-api-box {
    width: unset;
    max-width: 220px;
    /* height: 430px; */
    margin-right: 1rem;
    margin-bottom: unset;
  }

  .dashboard-startpagina-faq-ul li {
    margin-bottom: 16px;
  }
  #old-password-change-field,
  #new-password-change-field,
  #confirm-password-change-field {
    width: 500px;
  }
}

@media screen and (min-width: 1920px) {
  .dashboard-container {
    max-width: 1920px;
  }
  /* .dashboard-nav-section{
    width: calc((100vw - 1400px)/2);;
  } */
  /* .dashboard-nav-section > * {
    display: inline-block;
    margin-left: auto;
    margin-right: 2rem;
  } */
  .dashboard-title-box {
    width: 1920px;
    margin: 20px auto;
    padding: 0;
  }
  h1#dashboard-title {
    padding: 0;
  }
}

.new-badge,
.demo-badge {
  background-color: var(--warning-color);
  color: var(--light-color);
  border-radius: 2px;
  padding: 0 2px 2px;
  text-transform: uppercase;
}
.demo-badge {
  background-color: var(--secondary-color);
}

.dot-badge {
  background-color: var(--warning-color);
  width: 8px;
  height: 8px;
  border-radius: 8px;
  display: inline-block;
}

/* Checkout */
.checkout-container {
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}

.checkout-form {
  animation: fade 200ms ease-out;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  background-color: var(--light-color);
  padding: 0.2rem;
  border-radius: 8px;
}

.checkout-form-details {
  margin: 0 15px 20px;
  text-align: center;
}

.plan-titles {
  font-weight: 600;
}

.checkout-formgroup {
  border-style: none;
}

.checkout-formgroup--card {
  margin: 0;
  padding: 0;
  border-style: none;
  background-color: #fff;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 var(--success-color);
  border-radius: 4px;
}

.checkout-formrow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.StripeElement {
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 20px;
  height: 100%;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement {
  padding: 20px;
}

.checkout-formrowLabel {
  width: 15%;
  min-width: 70px;
  padding: 11px 0;
  color: var(--dark-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}

.checkout-formrowInput {
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 20px;
  height: 100%;
  width: 100%;
  border: none;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.checkout-formrowInput:focus {
  outline: none;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.checkout-submitBtn {
  display: block;
  font-size: 18px;
  width: 100%;
  height: 43px;
  margin: 20px 0;
  background-color: var(--primary-color);
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 var(--success-color);
  border-radius: 4px;
  border: none;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
  padding: 20px;
}

.checkout-submitBtn:active {
  background-color: var(--dark-color);
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #e298d8;
  transform: scale(0.99);
}

.checkout-submitBtn.checkout-submitBtn--error {
  transform: translateY(15px);
}
.checkout-submitBtn.checkout-submitBtn--error:active {
  transform: scale(0.99) translateY(15px);
}

.checkout-submitBtn:disabled {
  opacity: 0.5;
  cursor: default;
  box-shadow: none;
}

.ErrorMessage {
  color: var(--dark-color);
  display: flex;
  justify-content: center;
  padding: 0 15px;
  font-size: 13px;
  margin-top: 0px;
  width: 100%;
  opacity: 0;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
}

.ErrorMessage svg {
  margin-right: 10px;
}

.Result {
  margin-top: 50px;
  text-align: center;
  animation: fade 200ms ease-out;
}

.ResultTitle {
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center;
}

.ResultMessage {
  color: #9cdbff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6em;
  text-align: center;
}

.ResetButton {
  border: 0;
  cursor: pointer;
  background: transparent;
}

.horizontal-separator {
  display: block;
  color: #111111;
  margin: 1rem auto;
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  width: 30 px;
}

input:focus,
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}
.option-card {
  border: 1px solid var(--light-color);
  box-shadow: 0 0 11px -10px var(--primary-color);
  border-radius: 8px;
  width: 150px;
  margin-right: 1rem;
  height: 100px;
  background-color: white;
}
.checkout-options {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 200px;
  max-width: 600px;
  justify-content: center;
  gap: 0.5rem;
}
.active-payment {
  box-shadow: 0 0 2px 2px var(--primary-color);
  background-color: white;
  color: var(--primary-color);
}

@media screen and (max-width: 600px) {
  .creditCardForm {
    padding: 0px;
  }
}
.error-text {
  color: var(--danger-color);
}
#password-status {
  font-size: 0.8rem;
  color: var(--dark-color);
  position: relative;
  margin: 0.2rem 0 -0.5rem;
  padding: 0.4rem 0 0;
}
#password-status::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  /* margin: 2px; */
  height: 5px;
  width: 100%;
  background-color: var(--light-color);
}
#password-status.password-invalid::after {
  background-color: unset;
  background: linear-gradient(
    to right,
    var(--danger-color) 33%,
    var(--light-color) 0
  ) !important;
}
#password-status.password-invalid--full::after {
  background-color: unset;
  background: linear-gradient(
    to right,
    var(--danger-color) 100%,
    var(--light-color) 0
  ) !important;
}
#password-status.password-medium::after {
  background-color: unset;
  background: linear-gradient(
    to right,
    var(--warning-color) 66%,
    var(--light-color) 0
  ) !important;
}
#password-status.password-valid::after {
  background-color: unset;
  background: linear-gradient(
    to right,
    var(--success-color) 100%,
    var(--light-color) 0
  ) !important;
}

.signup-form-group--button-inside {
  position: relative;
}
.signup-form-group--button-inside button {
  position: absolute;
  font-size: 1.2rem;
  height: 1rem;

  background-color: unset;
  border: none;
  top: 1.4rem;
  right: 1rem;
  outline: none;
  display: none;
}
.signup-form-group--button-inside button.display-password-button {
  cursor: pointer;
  display: block;
  color: var(--dark-color);
}

.error-message {
  font-size: 0.875rem;
  color: var(--danger-color);
}

.signup-form-group {
  /* margin: 20px 0; */
  padding: 0.6rem 0;
  max-width: 375px;
}

#signup-form-field-1,
#signup-form-field-2,
#signup-form-field-3,
#signup-form-field-4,
#signup-form-field-5,
#signup-form-field-6 {
  max-width: 375px;
}

#auth-form-title {
  font-weight: 600;
  color: var(--dark-color);
}

.signup-btn-div,
.signin-btn-div {
  margin-top: 0.6rem;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.signup-btn-div p {
  max-width: 350px;
  text-align: center;
  margin-top: 12px;
}
#signup-btn {
  width: 100%;
  margin-right: 0;
  line-height: 3rem;
}

.form-link {
  color: var(--primary-color);
}

.flex-checkbox {
  display: flex;
  text-align: left;
}

.flex-checkbox input {
  margin-right: 5px;
  margin-top: 3px;
  /* height: 1.5rem;
	width: 1.5rem; */
}

#terms-text {
  text-align: center;
}

.flex-box {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}

.verify-account-container {
  min-height: 300px;
  margin: 140px 0 80px 0;
  text-align: center;
}

#verify-account-login-btn {
  margin-top: 40px;
}

.signup-container {
  width: 100%;
}

.auth-link {
  margin-top: 2rem;
  text-align: center;
}
.auth-link p {
  margin-top: 1rem;
}

/* MEDIA QUERIES MIN-WIDTH 320px */
@media screen and (min-width: 320px) {
  .flex-box {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .item-1 {
    order: 2;
    margin-top: 24px;
  }

  #auth-form-title {
    font-size: 30px;
    text-align: center;
  }

  .item-2 {
    order: 1;
  }
}

/* MEDIA QUERIES MIN-WIDTH 425px */
@media screen and (min-width: 425px) {
  #auth-form-title {
    font-size: 28px;
    text-align: center;
  }
}

/* MEDIA QUERIES MIN-WIDTH 768px */
@media screen and (min-width: 768px) {
  #auth-form-title {
    font-size: 36px;
  }

  .signup-btn-div p {
    margin-top: unset;
  }

  .signin-btn-div #signup-btn {
    width: unset;
  }
  .auth-link {
    margin-top: 0;
  }
  .auth-link p {
    margin-top: unset;
  }

  .signup-form-group--name {
    display: flex;
    flex-direction: row;
  }

  .signup-form-group--name .signup-form-group:first-of-type {
    margin-right: 1rem;
  }

  #signup-form-field-1,
  #signup-form-field-2,
  #signup-form-field-3,
  #signup-form-field-4,
  #signup-form-field-5,
  #signup-form-field-6 {
    max-width: unset;
  }
}

/* MEDIA QUERIES MIN-WIDTH 992px */
@media screen and (min-width: 992px) {
  .flex-box {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  .item-1 {
    order: 1;
    width: 45%;
    margin-top: 0;
    max-width: 375px;
  }

  #auth-form-title {
    font-size: 30px;
    text-align: center;
  }

  .item-2 {
    order: 2;
    width: 45%;
  }
}

/* Wallet css */
/* TODO: To be removed */
.wallet-card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  max-width: 800px;
  margin-bottom: 30px;

}

.wallet-title {
  color: #333;
  font-size: 24px;

}

.balance-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.currency-symbol {
  font-size: 24px;
  color: #333;
}

.balance-amount {
  font-size: 48px;
  color: #4CAF50;
  margin-left: 5px;
}

.table-container {
  max-width: 800px;
  margin: auto;
  overflow: hidden;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logs-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.logs-table th, .logs-table td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.logs-table th {
  background-color: #4CAF50;
  color: white;
}

.logs-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.logs-table td {
  color: #333;
}
